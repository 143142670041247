import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocale } from "@karpeleslab/klbfw";

// component
import Helmet from 'components/helmet/helmet';

export default function Guidebook() {
	const { t } = useTranslation();
	const currentLng = getLocale();

	return (
		<>
			<Helmet>
				<title>{t('common_guidebook')} : {t('common_ihic')}</title>
			</Helmet>
			<h1 className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_guidebook')}</span>
					<span className="c-pageHeader__eng">GUIDEBOOK</span>
				</div>
			</h1>

			<div className="c-topicPath c-block">
				<ol className="c-topicPath__list">
					<li className="c-topicPath__item"><Link to="/" className="c-topicPath__anchor">{t('common_home')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span>{t('common_guidebook')}</li>
				</ol>
			</div>
					
			<div className="p-guidebook c-block c-block--w740">

				<div className="p-guidebook__container">
					<p className="p-guidebook__img"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-guidebook-img01.png')} alt={t('guidebook_text')} className=""/></p>
					<p className="p-guidebook__text">{t('guidebook_text')}</p>
					{currentLng === 'ja-JP' && <p className="p-guidebook__text">{t('guidebook_text_1')}</p>}
				</div>

				<div className="p-guidebook__container">
					<p className="p-guidebook__img is-pinkmap"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-guidebook-img02.png')} alt={t('guidebook_pinkbook')} className="p-guidebook-img02"/></p>
					<p className="p-guidebook__text">{t('guidebook_pinkbook')}</p>
					{currentLng !== 'en-US' && <p className="p-guidebook__text">{t('guidebook_pinkbook_1')}</p>}
				</div>

				<div className="p-destination">
					<h2 className="p-destination__heading" dangerouslySetInnerHTML={{__html: t('guidebook_howget')}} />
					<ul className="p-destination__list">
						<li className="p-destination__item" dangerouslySetInnerHTML={{__html: t('guidebook_get_1')}} />
						<li className="p-destination__item">{t('guidebook_get_2')}</li>
						<li className="p-destination__item">{t('guidebook_get_3')}</li>
						<li className="p-destination__item">{t('guidebook_get_4')}</li>
						<li className="p-destination__item">{t('guidebook_get_5')}</li>
						<li className="p-destination__item">{t('guidebook_get_6')}</li>
					</ul>
					<p className="p-destination__text">{t('guidebook_map')}</p>
				</div>

			</div>
		
		</>
	);
}
