import React, { useState, useEffect } from "react";
import { useRest, useRestRefresh } from '@karpeleslab/react-klbfw-hooks';
import { Link, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import classNames from 'classnames';
import { useTranslation } from "react-i18next";

// component
import ComingSoon from "components/comingsoon/comingsoon";

// component
import Helmet from 'components/helmet/helmet';
import Paging from 'components/paging/paging';

export default function Info() {
	const { t } = useTranslation();
	const location = useLocation();
	const { pageNo, year } = useParams();
	const resultsPerPage = 10;
	const [page, setPage] = useState(pageNo === void 0 ? 1 : pageNo);
	const [selectedYear, setSelectedYear] = useState(year === void 0 ? moment().format('YYYY'): year);
	const [articleList, setArticle] = useRest("Content/Cms/@news:search",{
		page_no: page,
		results_per_page: resultsPerPage,
		query: {
			published: {'$lte': `${selectedYear}-12-31`, '$gte': `${selectedYear}-01-01`},
			tag: 'info'
		},
		sort: "published:desc"
	});

	const [menuYaer] = useState([
		moment().format('YYYY'),
		moment().subtract(1, 'years').format('YYYY'),
	]);

	const userRefresh = useRestRefresh("Content/Cms/@news:search", {
		page_no: page,
		results_per_page: resultsPerPage,
		query: {
			published: {'$lte': `${selectedYear}-12-31`, '$gte': `${selectedYear}-01-01`},
			tag: 'info'
		},
		sort: "published:desc"
	});

	useEffect(() => {
		setArticle(null)
		setPage(pageNo === void 0 ? 1 : pageNo)
		setSelectedYear(year === void 0 ? moment().format('YYYY'): year)
	}, [location.pathname, setArticle, pageNo, year])

	useEffect(() => {
		if (articleList === null) {
			userRefresh()
		}
	}, [page, articleList, userRefresh])

	const buildArticle = () => {

		if (articleList === null) return false;
		
		if (articleList.data.data.length === 0) {
			return <ComingSoon />
		}
		else {
			return (
				<>
					<ul className="c-info-list__list">
						{
							articleList.data.data.map((item, idx) => {
								return (
									<li key={idx} className="c-info-list__item">
										<span className="c-info-list__date">{moment(item.Published.unix * 1000).format('YYYY/MM/DD')}</span>
										<span className="c-info-list__text">
											<Link to={`/info/${moment(item.Published.unix * 1000).format('YYYY')}/${item.Slug}`} className="c-info-list__anchor">{item.Title}<span className="c-info-list__arrow" style={{ backgroundImage:`url(${require('assets/img/rocket-on/bullet-footerNav.svg')})`}}></span></Link>
										</span>
									</li>
								)
							})
						}
					</ul>
					<Paging list={articleList} page={page} path='info' year={`/${selectedYear}`} />
				</>
			)
		}	
	}



	return (
		<>
			<Helmet>
				<title>{t('common_info')} : {t('common_ihic')}</title>
			</Helmet>
			<div className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_info')}</span>
					<span className="c-pageHeader__eng">INFORMATION</span>
				</div>
			</div>

			<div className="c-topicPath c-block">
				<ol className="c-topicPath__list">
					<li className="c-topicPath__item"><Link to="/" className="c-topicPath__anchor">{t('common_home')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span>{t('common_info')}</li>
				</ol>
			</div>


			<div className="p-info-list c-block c-block--w980">
				<div className="p-info-list__year">
					<ol className="p-info-list-year__list">
						{menuYaer.map((item, idx) => {
							if (item >= 2020) {
								return (
									<li
										key={idx}
										className="p-info-list-year__item"
									>
										<Link to={`/info/${item}/list-1`} className={classNames("p-info-list-year__anchor", {
											'is-active': selectedYear === item
										})}>{item}</Link>
									</li>
								)
							} else {
								return null
							}
						})}
					</ol>
				</div>
				<div className="p-info-list__body">
					{buildArticle()}
				</div>
			</div>

		</>
	);
}
