import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocale } from "@karpeleslab/klbfw";
import classNames from 'classnames';

// component
import AboutNav from "components/aboutNab/aboutNav";
import Helmet from 'components/helmet/helmet';
import PreviewImg from "./previewImg";

// video
import video from "assets/img/guide_video.mp4";

export default function Exhibition() {
	const { t } = useTranslation();
	const currentLng = getLocale();
	
	const buildGuideText = () => {
		switch (currentLng) {
		case "en-US":
			return `${t('exhibition_about_guide_text')}<br/>${t('exhibition_about_guide_text_2')}`;
		case "ko-KR":
			return `${t('exhibition_about_guide_text')}<br/>${t('exhibition_about_guide_text_2')}`;
		default:
			return `${t('exhibition_about_guide_text')}<br/>${t('exhibition_about_guide_text_2')}<br/>${t('exhibition_about_guide_text_3')}<br/>${t('exhibition_about_guide_text_4')}`;
		}
	}


	const buildMap = () => {

		switch (currentLng) {
		case "en-US":
			return require('assets/img/rocket-on/img-exhibition-map-en-US.png');
		case "ko-KR":
			return require('assets/img/rocket-on/img-exhibition-map-kr.png');
		default:
			return require('assets/img/rocket-on/img-exhibition-map.svg');
		}

	}


	return (
		<>
			<Helmet>
				<title>{t('common_what_to_see')} : {t('common_ihic')}</title>
			</Helmet>
			<h1 className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_what_to_see')}</span>
					<span className="c-pageHeader__eng">EXHIBITION</span>
				</div>
			</h1>

			<div className="c-topicPath c-block">
				<ol className="c-topicPath__list">
					<li className="c-topicPath__item"><Link to="/" className="c-topicPath__anchor">{t('common_home')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span><Link to="" className="c-topicPath__anchor">{t('common_ihic')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span>{t('common_what_to_see')}</li>
				</ol>
			</div>
			<AboutNav />
			<section className="p-exhibition c-block">
				<div className="p-exhibition-header">
					<h2 className="c-heading--lv2" dangerouslySetInnerHTML={{__html: t('exhibition_about_exhibition')}} />
					<ul className="c-list--ul">
						<li className="c-list__item">{t('exhibition_about_exhibition_text_based')}</li>
						<li className="c-list__item">{t('exhibition_about_exhibition_text_regardless')}</li>
						<li className="c-list__item">{t('exhibition_about_exhibition_text_improving')}</li>
					</ul>
					{currentLng === "ja-JP" &&
						<p className="p-exhibition-header__btn"><a rel="noopener noreferrer" href="https://www.cas.go.jp/jp/sangyousekaiisan/seikaiisan_houkoku/221130.html" target="_blank" className="c-btn c-btn--big">{t('exhibition_about_exhibition_btn')}</a></p>
					}
					{currentLng === "en-US" &&
						<p className="p-exhibition-header__btn"><a rel="noopener noreferrer" href="https://whc.unesco.org/en/list/1484/documents/" target="_blank" className="c-btn c-btn--big">{t('exhibition_about_exhibition_btn')}</a></p>
					}
				</div>
				<hr className="c-separator c-separator--noMargin"/>
				<div className="p-exhibition-header">
					<h2 className="c-heading--lv2" dangerouslySetInnerHTML={{__html: t('exhibition_exhibits')}} />
					<p className="p-exhibition-header__subTitle">{t('exhibition_floor')}</p>
					<p className="p-exhibition-header__text">{t('exhibition_divided')}</p>
					<p className="p-exhibition-header__map"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={buildMap()} alt={t('exhibition_floor')} className="p-exhibition-header__img"/></p>
				</div>

				{/* <!--ゾーン1 : ここから--> */}
				<div className="p-exhibition-zone">
					<h3 className="p-exhibition-zone-heading">
						<div className="p-exhibition-zone-heading__box">
							<span className="p-exhibition-zone-heading__number">{t('exhibition_zone1')}</span>
							<span className="p-exhibition-zone-heading__title">{t('exhibition_intro')}</span>
						</div>
						<span className="p-exhibition-zone-heading__leadText" dangerouslySetInnerHTML={{__html: t('exhibition_ihic_intro')}} />
					</h3>
					<p className="p-exhibition-zone__text">{t('exhibition_about_zone1')}</p>
					<div className="p-exhibition-zone-figure">
						<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-exhibition-liquidGalaxy.jpg')} alt="" className="p-exhibition-zone-figure__img"/>
						<p className="p-exhibition-zone-figure__caption">{t('about_img_lg')}</p>
						<p className="p-exhibition-zone-figure__description">{t('exhibition_about_lg')}</p>
					</div>

					<PreviewImg
						imgs={[
							{
								thumd: require('assets/img/z1_1_thumd.png'),
								preview: require('assets/img/z1_1.png'),
							},
							{
								thumd: require('assets/img/z1_2_thumd.png'),
								preview: require('assets/img/z1_2.png'),
							},
							{
								thumd: require('assets/img/z1_2_2_thumd.png'),
								link: "https://youtu.be/Mnp-FyTHr-s?t=1757"
							},
							{
								thumd: require('assets/img/z1_3_thumd.png'),
								preview: require('assets/img/z1_3.png'),
							},
							{
								thumd: require('assets/img/z1_4_thumd.png'),
								preview: require('assets/img/z1_4.png'),
							},
							{
								thumd: require('assets/img/z1_5_thumd.png'),
								preview: require('assets/img/z1_5.png'),
							},
						]}
					/>
				</div>
				{/* <!--ゾーン1 : ここまで--> */}

				{/* <!--ゾーン2 : ここから--> */}
				<div className="p-exhibition-zone is-zone2">
					<h3 className="p-exhibition-zone-heading">
						<div className="p-exhibition-zone-heading__box">
							<span className="p-exhibition-zone-heading__number">{t('exhibition_zone2')}</span>
							<span className="p-exhibition-zone-heading__title">{t('exhibition_main')}</span>
						</div>
						<span className="p-exhibition-zone-heading__leadText">{t('exhibition_tracing')}</span>
					</h3>
					<p className="p-exhibition-zone__text" dangerouslySetInnerHTML={{__html: t('exhibition_about_zone2')}} />
					<div className="p-exhibition-zone-figure">
						<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-exhibition-panel.jpg')} alt="" className="p-exhibition-zone-figure__img"/>
						<p className="p-exhibition-zone-figure__caption">{t('about_img_pannel')}</p>
						<p className="p-exhibition-zone-figure__description">{t('exhibition_about_panel')}</p>
					</div>
					<div className="p-exhibition-zone-figure" style={{marginBottom: "60px"}}>
						<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-exhibition-tablet.jpg')} alt="" className="p-exhibition-zone-figure__img"/>
						<p className="p-exhibition-zone-figure__caption">{t('about_img_tablet')}</p>
						<p className="p-exhibition-zone-figure__description">{t('exhibition_about_tablet')}</p>
					</div>
					<PreviewImg
						imgs={[
							{
								thumd: require('assets/img/z2_1_thumd.png'),
								preview: require('assets/img/z2_1.png'),
							},
							{
								thumd: require('assets/img/z2_2_thumd.png'),
								preview: require('assets/img/z2_2.png'),
							},
							{
								thumd: require('assets/img/z2_3_thumd.png'),
								preview: require('assets/img/z2_3.png'),
							},
							{
								thumd: require('assets/img/z2_4_thumd.png'),
								preview: require('assets/img/z2_4.png'),
							},
							{
								thumd: require('assets/img/z2_5_thumd.png'),
								preview: require('assets/img/z2_5.png'),
							},
							{
								thumd: require('assets/img/z2_6_thumd.png'),
								preview: require('assets/img/z2_6.png'),
							},
						]}
					/>
				</div>
				{/* <!--ゾーン2 : ここまで--> */}

				{/* <!--ゾーン3 : ここから--> */}
				<div className="p-exhibition-zone">
					<h3 className="p-exhibition-zone-heading">
						<div className="p-exhibition-zone-heading__box">
							<span className="p-exhibition-zone-heading__number">{t('exhibition_zone3')}</span>
							<span className="p-exhibition-zone-heading__title">{t('exhibition_salon')}</span>
						</div>
					</h3>
					<p className="p-exhibition-zone__text">{t('exhibition_access')}</p>
					<div className="p-exhibition-zone__listContainer">
						<ol className={classNames("c-list--ol-alphabet", {
							"c-list--ol-alphabet-en": currentLng === "en-US"
						})}>
							<li className="c-list__item">{t('exhibition_list_a')}</li>
							<li className="c-list__item">{t('exhibition_list_b')}</li>
							<li className="c-list__item">{t('exhibition_list_c')}</li>
						</ol>

						<ol
							className={classNames("c-list--ol-alphabet", {
								"c-list--ol-alphabet-en": currentLng === "en-US"
							})}
							style={{
								counterReset: "ol 3"
							}}
						>
							<li className="c-list__item">{t('exhibition_list_d')}</li>
							<li className="c-list__item">{t('exhibition_list_e')}</li>
							<li className="c-list__item">{t('exhibition_list_f')}</li>
						</ol>
					</div>
					<div className="p-exhibition-zone-reference-img">
						<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-exhibition-reference01.jpg')} alt="資料写真" className="p-exhibition-zone-reference-img__photo"/>
						<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-exhibition-reference02.jpg')} alt="資料写真" className="p-exhibition-zone-reference-img__photo"/>
					</div>
					<PreviewImg
						imgs={[
							{
								thumd: require('assets/img/1_korea3_thumd.png'),
								preview: require('assets/img/1_korea3.png'),
							},
							{
								thumd: require('assets/img/2_testimony_thumd.png'),
								preview: require('assets/img/2_testimony.png'),
							},
							{
								thumd: require('assets/img/3_1_taiwan1_thumd.png'),
								preview: require('assets/img/3_2_taiwan2.png'),
							},
							{
								thumd: require('assets/img/z3_2_thumd.png'),
								preview: require('assets/img/z3_2.png'),
							},
							{
								thumd: require('assets/img/z3_1_thumd.png'),
								preview: require('assets/img/z3_1.png'),
							},
						]}
					/>
				</div>
				{/* <!--ゾーン3 : ここまで--> */}

				<hr className="c-separator c-separator--noMargin"/>

				<div className="p-exhibition-guide">
					<h3 className="c-heading--lv2">{t('exhibition_about_guide')}</h3>
					<div className="p-exhibition-guide__container">
						<div className="p-exhibition-guide__text_wrapper">
							<p className="p-exhibition-guide__text" dangerouslySetInnerHTML={{__html: buildGuideText()}} />
						</div>
						<div className="p-exhibition-guide__imgBox">
							<div className="p-exhibition-guide__video">
								<video controls poster={require('assets/img/guide_video_thumd.png')}>
									<source src={video} type="video/mp4" />
								</video>
							</div>
						</div>
					</div>
				</div>

				<hr className="c-separator c-separator--noMargin"/>

			</section>
			{/* <!--/.p-guide-foo---> */}
		
		</>
	);
}
