import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocale } from "@karpeleslab/klbfw";

// component
import Helmet from 'components/helmet/helmet';

export default function Sitemap() {
	const { t } = useTranslation();
	const currentLng = getLocale();
	return (
		<>
			<Helmet>
				<title>{t('common_sitemap')} : {t('common_ihic')}</title>
			</Helmet>
			<h1 className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_sitemap')}</span>
					<span className="c-pageHeader__eng">SITE MAP</span>
				</div>
			</h1>

			<div className="c-topicPath c-block">
				<ol className="c-topicPath__list">
					<li className="c-topicPath__item"><Link to="/" className="c-topicPath__anchor">{t('common_home')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span>{t('common_sitemap')}</li>
				</ol>
			</div>
			
			<div className="p-sitemap c-block">
				<ul className="p-sitemap-list">
					<li className="p-sitemap-list__item is-pc-wide">
						<Link to="/" className="p-sitemap-list__anchor">{t('common_top')}</Link>
					</li>
					<li className="p-sitemap-list__item is-pc-wide">
						<Link to="/guide" className="p-sitemap-list__anchor">{t('common_visiting')}</Link>
						<ul className="p-sitemap-list is-2ndLv">
							<li className="p-sitemap-list__item">
								<Link to="/guide" className="p-sitemap-list__anchor">{t('common_user_guide')}</Link>
							</li>
							<li className="p-sitemap-list__item">
								<Link to="/access" className="p-sitemap-list__anchor">{t('common_access')}</Link>
							</li>
							<li className="p-sitemap-list__item">
								<a href={`https://booking.ihic.jp/l/${currentLng}/`} className="p-sitemap-list__anchor">{t('common_booking')}</a>
							</li>
							<li className="p-sitemap-list__item">
								<Link to="/faq" className="p-sitemap-list__anchor">{t('common_faq')}</Link>
							</li>
							<li className="p-sitemap-list__item">
								<Link to="/manners" className="p-sitemap-list__anchor">{t('common_manners')}</Link>
							</li>
						</ul>
					</li>
					<li className="p-sitemap-list__item is-pc-wide">
						<Link to="/about-us" className="p-sitemap-list__anchor">{t('common_information_center')}</Link>
						<ul className="p-sitemap-list is-2ndLv">
							<li className="p-sitemap-list__item">
								<Link to="/about-us" className="p-sitemap-list__anchor">{t('common_about_us')}</Link>
							</li>
							<li className="p-sitemap-list__item">
								<Link to="/exhibition" className="p-sitemap-list__anchor">{t('common_what_to_see')}</Link>
							</li>
						</ul>
					</li>
					<li className="p-sitemap-list__item">
						<Link to="/event/" className="p-sitemap-list__anchor">{t('common_event_title')}</Link>
						<ul className="p-sitemap-list">
							<li className="p-sitemap-list__item">
								<Link to="/info/" className="p-sitemap-list__anchor">{t('common_info')}</Link>
							</li>
							<li className="p-sitemap-list__item">
								<Link to="/guidebook" className="p-sitemap-list__anchor">{t('common_guidebook')}</Link>
							</li>
							<li className="p-sitemap-list__item">
								<Link to="/publications" className="p-sitemap-list__anchor">{t('common_publications')}</Link>
							</li>
							<li className="p-sitemap-list__item">
								<Link to="/industrial" className="p-sitemap-list__anchor">{t('common_component')}</Link>
							</li>
						</ul>
					</li>
					<li className="p-sitemap-list__item is-privacy">
						<Link to="/privacy" className="p-sitemap-list__anchor">{t('common_privacy')}</Link>
					</li>
					<li className="p-sitemap-list__item is-sitemap">
						<Link to="/sitemap" className="p-sitemap-list__anchor">{t('common_sitemap')}</Link>
					</li>
				</ul>
			</div>
		</>
	);
}
