import React, { useRef, createRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocale } from "@karpeleslab/klbfw";

// component
import GuideNav from "components/guideNav/guidNav";
import Helmet from 'components/helmet/helmet';

export default function Faq() {
	const { t } = useTranslation();
	const currentLng = getLocale();

	const faq = [
		[
			t('faq_hours'),
			t('faq_hours_answer'),
		],
		[
			t('faq_charge'),
			t('faq_charge_answer'),
		],
		[
			t('faq_how_to_get'),
			t('faq_how_to_get_answer'),
		],
		[
			t('faq_parking'),
			t('faq_parking_answer'),
		],
		[
			t('faq_booking'),
			t('faq_booking_answer'),
		],
		[
			t('faq_book'),
			t('faq_book_answer', {lang: currentLng}),
		],
		[
			t('faq_guide'),
			t('faq_guide_answer'),
		],
		[
			t('faq_document'),
			t('faq_document_answer'),
		],
		[
			t('faq_need'),
			t('faq_need_answer'),
		],
		[
			t('faq_what'),
			t('faq_what_answer'),
		],
		[
			t('faq_exhibited'),
			`${t('faq_exhibited_answer_1')}<br/><br/>${t('faq_exhibited_answer_2')}<br/><br/>${t('faq_exhibited_answer_3')}<br/><br/>${t('faq_exhibited_answer_4')}`,
		],
		[
			t('faq_lg'),
			t('faq_lg_answer'),
		],
		[
			t('faq_wifi'),
			t('faq_wifi_answer'),
		],
		[
			t('faq_photo'),
			t('faq_photo_answer'),
		],
		[
			t('faq_pet'),
			t('faq_pet_answer'),
		],
		[
			t('faq_wheel'),
			t('faq_wheel_answer'),
		],
		[
			t('faq_space'),
			t('faq_space_answer'),
		],
		[
			t('faq_eat'),
			t('faq_eat_answer'),
		],
		[
			t('faq_goods'),
			t('faq_goods_answer'),
		],
		[
			t('faq_mark'),
			t('faq_mark_answer')
		]
	];

	const answerRef = useRef(faq.map(() => createRef()));

	const handleFaq = (num) => {

		const target = answerRef.current[num].current;
		const targetStyleHeight = target.style.height;
		const innerHeight = target.firstElementChild.clientHeight;

		if (targetStyleHeight === "") {
			target.setAttribute("style", `height: ${innerHeight}px`)
		} else {
			target.setAttribute("style", ``)
		}
	}

	const buildList = () => {
		return faq.map((item, idx) => {
			return (
				<dl key={idx} className="c-faq">
					<dt onClick={() => {handleFaq(idx)}} className="c-faq__question">{item[0]}</dt>
					<dd className="c-faq__answer" ref={answerRef.current[idx]}><div className="c-faq__answerInner" dangerouslySetInnerHTML={{__html:item[1]}}></div></dd>
				</dl>
			)
		})
	}

	return (
		<>
			<Helmet>
				<title>{t('common_faq')} : {t('common_ihic')}</title>
			</Helmet>
			<h1 className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_faq')} </span>
					<span className="c-pageHeader__eng">FAQ</span>
				</div>
			</h1>

			<div className="c-topicPath c-block">
				<ol className="c-topicPath__list">
					<li className="c-topicPath__item"><Link to="/" className="c-topicPath__anchor">{t('common_home')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span><Link to="" className="c-topicPath__anchor">{t('common_visiting')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span>{t('common_faq')} </li>
				</ol>
			</div>

			<GuideNav />
			<section className="p-faq">
				{buildList()}
			</section>
		</>
	);
}
