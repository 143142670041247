import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// component
import Helmet from 'components/helmet/helmet';

export default function Privacy() {
	const { t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>{t('common_privacy')} : {t('common_ihic')}</title>
			</Helmet>
			<h1 className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_privacy')}</span>
					<span className="c-pageHeader__eng">PRIVACY POLICY</span>
				</div>
			</h1>

			<div className="c-topicPath c-block">
				<ol className="c-topicPath__list">
					<li className="c-topicPath__item"><Link to="/" className="c-topicPath__anchor">{t('common_home')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span>{t('common_privacy')}</li>
				</ol>
			</div>
			
			<div className="p-privacy c-block c-block--w740">

				<p className="">{t('privacy_text_1')}</p>

				<h2 className="p-privacy__heading">{t('privacy_subtitle_1')}</h2>

				<p className="p-privacy__text">{t('privacy_text_1_1')}</p>

				<h2 className="p-privacy__heading">{t('privacy_subtitle_2')}</h2>

				<p className="p-privacy__text">{t('privacy_subtitle_2_1')}</p>

				<h2 className="p-privacy__heading">{t('privacy_subtitle_3')}</h2>

				<p className="p-privacy__text">{t('privacy_subtitle_3_1')}</p>

				<ol className="p-privacy-list">
					<li className="p-privacy-list__item">{t('privacy_subtitle_3_1_list1')}</li>
					<li className="p-privacy-list__item">{t('privacy_subtitle_3_1_list2')}</li>
					<li className="p-privacy-list__item">{t('privacy_subtitle_3_1_list3')}</li>
				</ol>

				<h2 className="p-privacy__heading">{t('privacy_subtitle_4')}</h2>

				<p className="p-privacy__text">{t('privacy_subtitle_4_1')}</p>

				<h2 className="p-privacy__heading">{t('privacy_subtitle_5')}</h2>

				<p className="p-privacy__text">{t('privacy_subtitle_5_1')}</p>

				<h2 className="p-privacy__heading">{t('privacy_subtitle_6')}</h2>

				<p className="p-privacy__text">{t('privacy_subtitle_6_1')}</p>
				<p className="p-privacy__text">{t('privacy_subtitle_6_2')}</p>

				<h2 className="p-privacy__heading">{t('privacy_subtitle_7')}</h2>

				<p className="p-privacy__text">{t('privacy_subtitle_7_1')}</p>

				<h2 className="p-privacy__heading">{t('privacy_subtitle_8')}</h2>

				<p className="p-privacy__text">{t('privacy_subtitle_8_1')}</p>
				<p className="p-privacy__text">{t('privacy_subtitle_8_2')}</p>
				<p className="p-privacy__text">{t('privacy_subtitle_8_3')}</p>
				<p className="p-privacy__text">{t('privacy_subtitle_8_4')}</p>

			</div>
		</>
	);
}
