import React from "react";
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import classNames from 'classnames';
import { getLocale } from "@karpeleslab/klbfw";

// component
import Helmet from 'components/helmet/helmet';
import NotFound from 'views/notfound/notfound';

export default function EventDetail() {

	const { t } = useTranslation();
	const { slug } = useParams();
	const [article] = useRest(`Content/Cms/@news:loadSlug`, { slug: slug }, true);
	const currentLng = getLocale();

	const buildMainImg = () => {

		if (article.data.content_cms_entry_data.Top_Drive_Item__ === null) {
			return null
		} else {
			return <img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} style={{marginTop: "40px"}} src={article.data.content_cms_entry_data.Top_Drive_Item.View_Url} alt={article.data.content_cms_entry_data.Title} />
		}

	}

	const buildArtcle = () => {

		if (article === null) return false;
		
		if (article.data.content_cms_entry_data === null || article.data.content_cms_entry_data === void 0) {
			return <p style={{textAlign: "center"}}>{t('article_not')}</p>
		}
		else {
			return (
				<>
					<Helmet>
						<title>{article.data.content_cms_entry_data.Title} : {t('common_ihic')}</title>
					</Helmet>
					<div className="c-topicPath c-block">
						<ol className="c-topicPath__list">
							<li className="c-topicPath__item"><Link to="/" className="c-topicPath__anchor">{t('common_home')}</Link></li>
							<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span><Link to="/event" className="c-topicPath__anchor">{t('common_event_list')}</Link></li>
							<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span>{article.data.content_cms_entry_data.Title}</li>
						</ol>
					</div>
					<div className="p-topics-entry c-block c-block--w740">
						<h1 className="p-topics-entry-heading">
							<span className="p-topics-entry-heading__date">{moment(article.data.content_cms_entry_data.Published.unix * 1000).format('YYYY/MM/DD')}</span>
							<span className="p-topics-entry-heading__title">{article.data.content_cms_entry_data.Title}</span>
						</h1>
						{buildMainImg()}
						<div dangerouslySetInnerHTML={{__html: article.data.content_cms_entry_data.Contents}} className="p-topics-entry__body">
						</div>
					</div>


					<div className="p-topics-entry-pager c-block">
						{/* <span href="" className="p-topics-entry-pager__prev is-disabled">前のトピックへ<span className="c-pager__arrow" style={{ backgroundImage:`url(${require('assets/img/rocket-on/img-arrow-l2r--white.svg')})`}}></span></span> */}
						<p className={classNames("p-topics-entry-pager__btns", {
							"p-topics-entry-pager__btns-en": currentLng === "en-US"
						})}>
							<Link to="/event/" className={classNames("c-btn c-btn--regular", {
								"c-btn--regular--en": currentLng === "en-US"
							})}>{t('common_back_to_events')}</Link>
						</p>
						{/* <Link href="" className="p-topics-entry-pager__next">次のトピックへ<span className="c-pager__arrow" style={{ backgroundImage:`url(${require('assets/img/rocket-on/img-arrow-l2r--white.svg')})`}}></span></Link> */}
					</div>
				</>
			)
		}	
	}

	if ( article === false ) return <NotFound />
	
	return (
		<>
			<div className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_event_title')}</span>
					<span className="c-pageHeader__eng c-pageHeader__eng--2lines">{t("common_event_title_en")}</span>
				</div>
			</div>
			{buildArtcle()}
		</>
	);
}
