import React, { useState, useRef } from "react";
import ScrollLock from 'react-scrolllock';
import classNames from 'classnames';
import Slider from "react-slick";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

// slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// component

// style
import style from "assets/scss/modules/previewImg.module.scss";

export default function PreviewImg({ imgs = [
	{
		thumd: require('assets/img/2_testimony_thumd.png'),
		preview: require('assets/img/1_korea3.png'),
	}
] }) {
	const sliderRef = useRef();
	const [currentImg, setCurrentImg] = useState(null);
	const [ scrollLock, setScrollLock ] = useState(false);
	const [clickable, setClickable] = useState(true);
	const [ previewLoad, setPreviewLoad ] = useState(true);
	const transformComponentRef = useRef(null);
	const previerImg = useRef(null);

	const previewClose = (e) => {
		e.preventDefault();
		setCurrentImg(null);
		setScrollLock(false);
	}

	const onSliderChange = () => {
		setTimeout(() => {
			setClickable(true);
		}, 500)
	};

	const settings = {
		dots: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		infinite: true,
		arrows: false,
		autoplay: true,
		afterChange: onSliderChange,
		beforeChange: () => setClickable(false),
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
				}
			},
		]
	};

	return (
		<>
			{currentImg !== null && 
			<>
				<div onClick={(e) => {
      		previewClose(e);
					setPreviewLoad(true);
      	}} className={style["preview-overlay"]}>
					{ previewLoad && <img className={style["preview-load"]} src={require('assets/img/load.gif')} alt="" />}
				</div>
				<div className={style["preview-close"]}
					onClick={(e) => {
						previewClose(e);
						setPreviewLoad(true);
					}}
				>
					<span className={style["preview-close-btn"]} style={{
						backgroundImage: `url(${require('assets/img/rocket-on/img-navDrawer--close.svg')})`
					}}></span>
				</div>

				<div className={classNames(style["preview-wrapper"], {
					[style["preview-wrapper-active"]]: !previewLoad
				})}>
					<TransformWrapper
						initialScale={1}
						minScale={0.5}
						onInit={() => {
							transformComponentRef.current.resetTransform();
							setTimeout(() => {
								const imgRect = previerImg.current.getBoundingClientRect();
								const windowHeight = window.innerHeight;
								const windowWidth = window.innerWidth;
								if(imgRect.height > windowHeight && imgRect.width <= windowWidth) {
									const initialX = (windowWidth - imgRect.width) / 2;
									transformComponentRef.current.setTransform(initialX, 0, 1, 0)
								} else {
									transformComponentRef.current.centerView(undefined, 0);
								}
								setPreviewLoad(false);
							}, 201);
						}}
						ref={transformComponentRef}
					>
						<TransformComponent height="100%">
							<img src={currentImg !== null ? currentImg : ""} alt="" width="100%" ref={previerImg}/>
						</TransformComponent>
					</TransformWrapper>
				</div>
			</>
			}
			<div className={style["preview-list"]}>
				<div onClick={() => {
					sliderRef.current.slickPrev();
				}} className={`${style["preview-list-btn"]} ${style["preview-list-btn--prev"]}`}>＜</div>
				<div onClick={() => {
					sliderRef.current.slickNext();
				}} className={`${style["preview-list-btn"]} ${style["preview-list-btn--next"]}`}>＞</div>
				<Slider ref={sliderRef} {...settings}>
					{imgs.map((item, idx) => {
						if (item.link === void 0) {
							return <div className={style["preview-item-wrapper"]} key={idx}>
								<img onClick={() => {
									if (clickable) {
										setScrollLock(true);
										setCurrentImg(item.preview);
									}
								}} className={style["preview-item"]} src={item.thumd} alt="" />
							</div>
						} else {
							return <div className={style["preview-item-wrapper"]} key={idx}>
								<a href={item.link} target="_blank" rel="noopener noreferrer">
									<img className={style["preview-item"]} src={item.thumd} alt="" /></a>
							</div>
						}
					})}
				</Slider>
			</div>
			<ScrollLock isActive={scrollLock} />
		</>
	);
}
