import React from "react";
import Helmet from 'components/helmet/helmet';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import style from "assets/scss/modules/notfound.module.scss";

export default function NotFound() {
	const { t } = useTranslation();
	return (
		<>
			<Helmet>
				<title>404 not found : {t('common_ihic')}</title>
			</Helmet>
			<h1 className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_404')}</span>
					<span className="c-pageHeader__eng">Not Found</span>
				</div>
			</h1>
			<div className={`${style['notfound']} c-block`}>
				<p className={style['notfound-text']}>{t('common_404_text')}</p>
				<p className={style['notfound-text']}>{t('common_404_text_2')}</p>
				<p className={style['notfound-btn']}><Link className="c-btn c-btn--regular" to="/">{t('common_top')}</Link></p>
			</div>
		</>
	);
}