import React from "react";
import { useLocation, Link } from "react-router-dom";
import classNames from 'classnames';
import { useTranslation } from "react-i18next";

export default function GuideNav() {

	const { t } = useTranslation();
	const location = useLocation();

	return (
		<>
			<div className="c-navLocal">
				<ul className="c-navLocal__list">
					<li className="c-navLocal__item"><Link to="/about-us" className={classNames("c-navLocal__anchor", {
						'c-navLocal__anchor--active': location.pathname === "/about-us"
					})}>{t('common_about_us')}</Link></li>
					<li className="c-navLocal__item"><Link to="/exhibition" className={classNames("c-navLocal__anchor", {
						'c-navLocal__anchor--active': location.pathname === "/exhibition"
					})}>{t('common_what_to_see')}</Link></li>
				</ul>
			</div>
		</>
	);
}
