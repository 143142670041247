import React, { useState } from "react";
import { Link } from "react-router-dom";
import ScrollLock from 'react-scrolllock';
import { useTranslation } from "react-i18next";
import { getLocale } from "@karpeleslab/klbfw";

// component
import Helmet from 'components/helmet/helmet';

export default function Industrial() {
	const { t } = useTranslation();
	const [ modalView, setModalView ] = useState(false);
	const [ modalVal, setModalVal ] = useState(null);
	const [ scrollLock, setScrollLock ] = useState(false);
	const currentLng = getLocale();

	const handleModal = (modalId) => {
		if (modalView === false) {
			setModalView(true);
			setScrollLock(true);
			setModalVal(detailJson[modalId]);
		}
		else {
			setModalView(false);
			setScrollLock(false);
			setModalVal(null);
		}
	}
	
	const handleModalClose = () => {
		setModalView(false);
		setScrollLock(false);
		setModalVal(null);
	}

	const detailJson = {
		"#modal-area1-hagihansharo": [
			'single',
			'modal-area1-hagihansharo',
			`${t('industrial_area1')} ${t('industrial_area1_areaname')}`,
			t('industrial_area1_subtile'),
			t('industrial_area1_01_subtitle'),
			t('industrial_area1_01'),
			[
				[ '',
					t('industrial_area1_01_child_text'),
					'img-heritage-area1-hagihansharo.jpg'
				]
			]
		],
		"#modal-area1-EbisugahanaShipyard":[ 'single',
			'modal-area1-EbisugahanaShipyard',
			`${t('industrial_area1')} ${t('industrial_area1_areaname')}`,
			t('industrial_area1_subtile'),
			t('industrial_area1_02_subtitle'),
			t('industrial_area1_02'),
			[
				[ '',
					t('industrial_area1_02_child_text'),
					'img-heritage-area1-EbisugahanaShipyard.jpg'
				]
			]
		],
		"#modal-area1-OhitayamaTataraIronWorks":[ 'single',
			'modal-area1-OhitayamaTataraIronWorks',
			`${t('industrial_area1')} ${t('industrial_area1_areaname')}`,
			t('industrial_area1_subtile'),
			t('industrial_area1_03_subtitle'),
			t('industrial_area1_03'),
			[
				[ '',
					t('industrial_area1_03_child_text'),
					'img-heritage-area1-OhitayamaTataraIronWorks.jpg'
				]
			]
		],
		"#modal-area1-HagiCastleTown":[ 'single',
			'modal-area1-HagiCastleTown',
			`${t('industrial_area1')} ${t('industrial_area1_areaname')}`,
			t('industrial_area1_subtile'),
			t('industrial_area1_04_subtitle'),
			t('industrial_area1_04'),
			[
				[ '',
					t('industrial_area1_04_child_text'),
					'img-heritage-area1-HagiCastleTown.jpg'
				]
			]
		],
		"#modal-area1-Shoukasonjuku":[ 'single',
			'modal-area1-Shoukasonjuku',
			`${t('industrial_area1')} ${t('industrial_area1_areaname')}`,
			t('industrial_area1_subtile'),
			t('industrial_area1_05_subtitle'),
			t('industrial_area1_05'),
			[
				[ '',
					t('industrial_area1_05_child_text'),
					'img-heritage-area1-Shoukasonjuku.jpg'
				]
			]
		],
		"#modal-area2-shuseikan":[ 'multi',
			'modal-area2-shuseikan',
			`${t('industrial_area2')} ${t('industrial_area2_areaname')}`,
			t('industrial_area2_subtile'),
			t('industrial_area2_01_subtitle'),
			t('industrial_area2_01'),
			[
				[ t('industrial_area2_01_child_title_01'),
					t('industrial_area2_01_child_text_01'),
					'img-heritage-area2-shuseikan01.jpg'
				],
				[ t('industrial_area2_01_child_title_02'),
					t('industrial_area2_01_child_text_02'),
					'img-heritage-area2-shuseikan02.jpg'
				],
				[ t('industrial_area2_01_child_title_03'),
					t('industrial_area2_01_child_text_03'),
					'img-heritage-area2-shuseikan03.jpg'
				]
			]
		],
		"#modal-area2-TerayamaCharcoalKiln":[ 'single',
			'modal-area2-TerayamaCharcoalKiln',
			`${t('industrial_area2')} ${t('industrial_area2_areaname')}`,
			t('industrial_area2_subtile'),
			t('industrial_area2_02_subtitle'),
			t('industrial_area2_02'),
			[
				[ '',
					t('industrial_area2_02_child_text'),
					'img-heritage-area2-TerayamaCharcoalKiln.jpg'
				]
			]
		],
		"#modal-area2-SekiyoshiSluiceGate":[ 'single',
			'modal-area2-SekiyoshiSluiceGate',
			`${t('industrial_area2')} ${t('industrial_area2_areaname')}`,
			t('industrial_area2_subtile'),
			t('industrial_area2_03_subtitle'),
			t('industrial_area2_03'),
			[
				[ '',
					t('industrial_area2_03_child_text'),
					'img-heritage-area2-SekiyoshiSluiceGate.jpg'
				]
			]
		],
		"#modal-area3-nirayamaHansharo":[ 'single',
			'modal-area3-nirayamaHansharo',
			`${t('industrial_area3')} ${t('industrial_area3_areaname')}`,
			t('industrial_area3_subtile'),
			t('industrial_area3_01_subtitle'),
			t('industrial_area3_01'),
			[
				[ '',
					t('industrial_area3_01_child_text'),
					'img-heritage-area3-nirayamaHansharo.jpg'
				]
			]
		],
		"#modal-area4-hashinotekkouzan":[ 'single',
			'modal-area4-hashinotekkouzan',
			`${t('industrial_area4')} ${t('industrial_area4_areaname')}`,
			t('industrial_area4_subtile'),
			t('industrial_area4_01_subtitle'),
			t('industrial_area4_01'),
			[
				[ '',
					t('industrial_area4_01_child_text'),
					'img-heritage-area4-hashinotekkouzan.jpg'
				]
			]
		],
		"#modal-area5-mietsu":[ 'single',
			'modal-area5-mietsu',
			`${t('industrial_area5')} ${t('industrial_area5_areaname')}`,
			t('industrial_area5_subtile'),
			t('industrial_area5_01_subtitle'),
			t('industrial_area5_01'),
			[
				[ '',
					t('industrial_area5_01_child_text'),
					'img-heritage-area5-mietsu.jpg'
				]
			]
		],
		"#modal-area6-kosugeShusenba":[ 'single',
			'modal-area6-kosugeShusenba',
			`${t('industrial_area6')} ${t('industrial_area6_areaname')}`,
			t('industrial_area6_subtile'),
			t('industrial_area6_01_subtitle'),
			t('industrial_area6_01'),
			[
				[ '',
					t('industrial_area6_01_child_text'),
					'img-heritage-area6-kosugeShusenba.jpg'
				]
			]
		],
		"#modal-area6-daisanSenkyo":[ 'single',
			'modal-area6-daisanSenkyo',
			`${t('industrial_area6')} ${t('industrial_area6_areaname')}`,
			t('industrial_area6_subtile'),
			t('industrial_area6_02_subtitle'),
			t('industrial_area6_02'),
			[
				[ '',
					t('industrial_area6_02_child_text'),
					'img-heritage-area6-daisanSenkyo.jpg'
				]
			]
		],
		"#modal-area6-cantileverCrane":[ 'single',
			'modal-area6-cantileverCrane',
			`${t('industrial_area6')} ${t('industrial_area6_areaname')}`,
			t('industrial_area6_subtile'),
			t('industrial_area6_03_subtitle'),
			t('industrial_area6_03'),
			[
				[ '',
					t('industrial_area6_03_child_text'),
					'img-heritage-area6-cantileverCrane.jpg'
				]
			]
		],
		"#modal-area6-kigataba":[ 'single',
			'modal-area6-kigataba',
			`${t('industrial_area6')} ${t('industrial_area6_areaname')}`,
			t('industrial_area6_subtile'),
			t('industrial_area6_04_subtitle'),
			t('industrial_area6_04'),
			[
				[ '',
					t('industrial_area6_04_child_text'),
					'img-heritage-area6-kigataba.jpg'
				]
			]
		],
		"#modal-area6-senshokaku":[ 'single',
			'modal-area6-senshokaku',
			`${t('industrial_area6')} ${t('industrial_area6_areaname')}`,
			t('industrial_area6_subtile'),
			t('industrial_area6_05_subtitle'),
			t('industrial_area6_05_01'),
			[
				[ '',
					t('industrial_area6_05_child_text'),
					'img-heritage-area6-senshokaku.jpg'
				]
			]
		],
		"#modal-area6-takashimaTanko":[ 'single',
			'modal-area6-takashimaTanko',
			`${t('industrial_area6')} ${t('industrial_area6_areaname')}`,
			t('industrial_area6_06'),
			t('industrial_area6_06_subtitle'),
			t('industrial_area6_06_01'),
			[
				[ '',
					t('industrial_area6_06_child_text'),
					'img-heritage-area6-takashimaTanko.jpg'
				]
			]
		],
		"#modal-area6-hashimaTanko":[ 'single',
			'modal-area6-hashimaTanko',
			`${t('industrial_area6')} ${t('industrial_area6_areaname')}`,
			t('industrial_area6_06'),
			t('industrial_area6_07_subtitle'),
			t('industrial_area6_07'),
			[
				[ '',
					t('industrial_area6_07_child_text'),
					'img-heritage-area6-hashimaTanko.jpg'
				]
			]
		],
		"#modal-area6-gloverhouse":[ 'single',
			'modal-area6-gloverhouse',
			`${t('industrial_area6')} ${t('industrial_area6_areaname')}`,
			t('industrial_area6_08'),
			t('industrial_area6_08_subtitle'),
			t('industrial_area6_08'),
			[
				[ '',
					t('industrial_area6_08_child_text'),
					'img-heritage-area6-gloverhouse.jpg'
				]
			]
		],
		"#modal-area7-miikeTanko":[ 'multi',
			'modal-area7-miikeTanko',
			`${t('industrial_area7')} ${t('industrial_area7_areaname')}`,
			t('industrial_area7_subtile_1'),
			t('industrial_area7_01_subtitle'),
			t('industrial_area7_01'),
			[
				[ t('industrial_area7_01_child_title_01'),
					t('industrial_area7_01_child_text_01'),
					'img-heritage-area7-miikeTanko01.jpg'
				],
				[ t('industrial_area7_01_child_title_02'),
					t('industrial_area7_01_child_text_02'),
					'img-heritage-area7-miikeTanko02.jpg'
				],
				[ t('industrial_area7_01_child_title_03'),
					t('industrial_area7_01_child_text_03'),
					'img-heritage-area7-miikeTanko03.jpg'
				],
				[ t('industrial_area7_01_child_title_04'),
					t('industrial_area7_01_child_text_04'),
					'img-heritage-area7-miikeTanko04.jpg'
				],
			]
		],
		"#modal-area7-misumiWestPort":[ 'single',
			'modal-area7-misumiWestPort',
			`${t('industrial_area7')} ${t('industrial_area7_areaname')}`,
			t('industrial_area7_subtile_2'),
			t('industrial_area7_02_subtitle'),
			t('industrial_area7_02'),
			[
				[ '',
					t('industrial_area7_02_child_text'),
					'img-heritage-area7-misumiWestPort.jpg'
				]
			]
		],
		"#modal-area8-yahata":[ 'multi',
			'modal-area8-yahata',
			`${t('industrial_area8')} ${t('industrial_area8_areaname')}`,
			t('industrial_area8_subtitle'),
			t('industrial_area8_01_subtitle'),
			t('industrial_area8_01'),
			[
				[ t('industrial_area8_01_child_title_01'),
					t('industrial_area8_01_child_text_01'),
					'img-heritage-area8-yahata01.jpg'
				],
				[ t('industrial_area8_01_child_title_02'),
					t('industrial_area8_01_child_text_02'),
					'img-heritage-area8-yahata02.jpg'
				],
				[ t('industrial_area8_01_child_title_03'),
					t('industrial_area8_01_child_text_03'),
					'img-heritage-area8-yahata03.jpg'
				]
			]
		],
		"#modal-area8-ongagawa":[ 'single',
			'modal-area8-ongagawa',
			`${t('industrial_area8')} ${t('industrial_area8_areaname')}`,
			t('industrial_area8_subtitle_2'),
			t('industrial_area8_02_subtitle'),
			t('industrial_area8_02'),
			[
				[ '',
					t('industrial_area8_02_child_text'),
					'img-heritage-area8-ongagawa.jpg'
				]
			]
		],
	}	

	const heritages = [
		[
			[t('industrial_area1_subtile'),
				[
					[t('industrial_area1_01'), '#modal-area1-hagihansharo'],
					[t('industrial_area1_02'), '#modal-area1-EbisugahanaShipyard'],
					[t('industrial_area1_03'), '#modal-area1-OhitayamaTataraIronWorks'],
					[t('industrial_area1_04'), '#modal-area1-HagiCastleTown'],
					[t('industrial_area1_05'), '#modal-area1-Shoukasonjuku']
				]
			]
		],
		[
			[ t('industrial_area2_subtile'),
				[
					[t('industrial_area2_01'), '#modal-area2-shuseikan'],
					[t('industrial_area2_02'), '#modal-area2-TerayamaCharcoalKiln'],
					[t('industrial_area2_03'), '#modal-area2-SekiyoshiSluiceGate']
				]
			]
		],
		[
			[ t('industrial_area3_subtile'),
				[
					[t('industrial_area3_01'), '#modal-area3-nirayamaHansharo']
				]
			]
		],
		[
			[ t('industrial_area4_subtile'),
				[
					[t('industrial_area4_01'), '#modal-area4-hashinotekkouzan']
				]
			]
		],
		[
			[ t('industrial_area5_subtile'),
				[
					[t('industrial_area5_01'), '#modal-area5-mietsu']
				]
			]
		],
		[
			[ t('industrial_area6_subtile'),
				[
					[t('industrial_area6_01'), '#modal-area6-kosugeShusenba'],
					[t('industrial_area6_02'), '#modal-area6-daisanSenkyo'],
					[t('industrial_area6_03'), '#modal-area6-cantileverCrane'],
					[t('industrial_area6_04'), '#modal-area6-kigataba'],
					[t('industrial_area6_05'), '#modal-area6-senshokaku']
				]
			],
			[
				t('industrial_area6_06'),
				[
					[t('industrial_area6_06_01'), '#modal-area6-takashimaTanko'],
					[t('industrial_area6_07'), '#modal-area6-hashimaTanko']
				]
			],
			[
				t('industrial_area6_08'),
				[
					[t('industrial_area6_08'), '#modal-area6-gloverhouse']
				]
			]
		],
		[
			[ t('industrial_area7_subtile_1'),
				[
					[t('industrial_area7_01'), '#modal-area7-miikeTanko'],
				],
			],
			[ t('industrial_area7_subtile_2'),
				[
					[t('industrial_area7_02'), '#modal-area7-misumiWestPort']
				]
			]
		],
		[
			[ t('industrial_area8_subtitle'),
				[
					[t('industrial_area8_01'), '#modal-area8-yahata'],
					[t('industrial_area8_02'), '#modal-area8-ongagawa']
				]
			]
		],
	]



	const buildChildList = (item) => {
		return (
			<>
				<ul className="p-heritage-table__list">
					{
						item.map((child, idx) => {
							return (
								<li
									key={idx}
									className="p-heritage-table__item"
									onClick={() => {handleModal(child[1])}}
								>
									<span
										dangerouslySetInnerHTML={{__html:child[0]}}
										className="p-heritage-table__anchor js-modal--open"
									></span>
								</li>
							)
						})

					}
				</ul>
			</>
		)
	}

	const buildList = () => {
		return (
			heritages.map((item, idx) => {
				return (
					<div key={idx} className={`p-heritage-block is-area0${idx + 1} is-area0${idx + 1}-${currentLng}`}>
						<p className="p-heritage-block__title">AREA {idx + 1}</p>
						{item.map((detail, idx2) => {
							return (
								<div key={idx2} className="p-heritage-table">
									<p className="p-heritage-table__prefecture">{detail[0]}</p>
									{buildChildList(detail[1])}
								</div>
							)
						})}
					</div>
				)
			})
		)
	}

	const buildArticleList = () => {
		return modalVal[6].map((item, idx) => {
			return (
				<div key={idx} className="p-heritage-modal-body">
					<div className="p-heritage-modal-body__text">
						<p className="p-heritage-modal__subTitle">{item[0]}</p>
						<p className="p-heritage-modal__text">{item[1]}</p>
					</div>
					<div className="p-heritage-modal-body__img">
						<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={`${require(`assets/img/rocket-on/${item[2]}`)}`} alt="遠賀川水源地ポンプ室の写真" className="p-heritage-modal__img"/>
					</div>
				</div>
			)
		})
	}

	const buildArticle = () => {
		if (modalVal[0] === "single") {
			return (
				<>
					<div className="p-heritage-modal-body">
						<div className="p-heritage-modal-body__text">
							<p className="p-heritage-modal__copy">{modalVal[4]}</p>
							<h2
								dangerouslySetInnerHTML={{__html: modalVal[5]}}
								className="p-heritage-modal__title"
							></h2>
							<p className="p-heritage-modal__text">{modalVal[6][0][1]}</p>
						</div>
						<div className="p-heritage-modal-body__img">
							<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={`${require(`assets/img/rocket-on/${modalVal[6][0][2]}`)}`} alt="遠賀川水源地ポンプ室の写真" className="p-heritage-modal__img"/>
						</div>
					</div>
				</>
			)
		} else {
			return (
				<>
					<div className="p-heritage-modal-heading-multi">
						<p className="p-heritage-modal__copy">{modalVal[4]}</p>
						<h2
							dangerouslySetInnerHTML={{__html: modalVal[5]}}
							className="p-heritage-modal__title"
						></h2>
					</div>
					{buildArticleList()}
				</>
			)
		}
	}

	const buildModal = () => {
		if (modalView && modalVal !== null) {
			return (
				<>
					<div className="modaal-wrapper modaal-inline" id="modaal_160376799574174a42b552ee1b">
						<div className="modaal-outer-wrapper">
							<div className="modaal-inner-wrapper">
								<div className="modaal-container">
									<div className="modaal-content modaal-focus" aria-hidden="false" aria-label="Dialog Window - Close (Press escape to close)" role="dialog" tabIndex="0">
										<div className="modaal-content-container" role="document">
						
											<div className="p-heritage-modal">
												<p className="p-heritage-modal-heading">
													<span className="p-heritage-modal-heading__area">{modalVal[2]}</span>
													<span className="p-heritage-modal-heading__copy">{modalVal[3]}</span>
												</p>
							
							
												{buildArticle()}
							
												<div className="p-heritage-modal__btn">
													<button onClick={() => {handleModalClose()}} className="p-heritage-modal__close js-modal--close">閉じる</button>
												</div>
												<button onClick={() => {handleModalClose()}} className="p-heritage-modal__closeBtn js-modal--close"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-modal-close.svg')} alt="閉じる"/></button>
											</div>
						
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="modaal-overlay" id="modaal_160369910180224a4a9e6d3557_overlay" style={{
						background: "rgb(0, 0, 0)",
						opacity: 0.7
					}}></div>
				</>
			)
		} else {
			return null;
		}
	}


	return (
		<>
			<Helmet>
				<title>{t('common_industrial')} : {t('common_ihic')}</title>
			</Helmet>
			<h1 className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_industrial')}</span>
					<span className="c-pageHeader__eng c-pageHeader__eng--3lines">SITES OF JAPAN’S <br/>
						MEIJI INDUSTRIAL <br className="forSP"/>
						REVOLUTION</span>
				</div>
			</h1>

			<div className="c-topicPath c-block">
				<ol className="c-topicPath__list">
					<li className="c-topicPath__item"><Link to="/" className="c-topicPath__anchor">{t('common_home')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span>{t('common_industrial')}</li>
				</ol>
			</div>

			<div className="c-block">
				<div className="p-heritage">
					<p className="p-heritage-block-map"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-heritage-map.svg')} alt=""/></p>
					{buildList()}
				</div>
			</div>
			{buildModal()}
			<ScrollLock isActive={scrollLock} />
		</>
	);
}
