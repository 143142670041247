import React from "react";

// style
import style from "assets/scss/modules/comingsoon.module.scss";

export default function ComingSoon() {
	return (
		<>
			<div
				className={style['coming-wrapper']}
			>
				<p
					className={style['coming-text']}
				>coming soon</p>
			</div>
		</>
	);
}
