import React from "react";
import { Link } from "react-router-dom";
import classNames from 'classnames';
import { useTranslation } from "react-i18next";


export default function Paging({page, list, path, year = ""}) {
	const { t } = useTranslation();

	const buildPageNum = () => {
		const pageNum = parseInt(page);

		var pageNumList = [];
		for (let i = 1; i <= list.paging.page_max; i++) {
			if (pageNum === 1) {
				if (pageNum + 4 > i) {
					pageNumList.push(<li key={i} className="c-pager__item"><Link to={`/${path}${year}/list-${i}`} className={classNames("c-pager__anchor", { 'is-active': i === list.paging.page_no })}>{i}</Link></li>);
				}
			}
			else if (pageNum === list.paging.page_max-1 ) {
				if (pageNum - 3 <  i && pageNum + 2 > i) {
					pageNumList.push(<li key={i} className="c-pager__item"><Link to={`/${path}${year}/list-${i}`} className={classNames("c-pager__anchor", { 'is-active': i === list.paging.page_no })}>{i}</Link></li>);
				}
			}
			else if (pageNum === list.paging.page_max ) {
				if (pageNum - 4 <  i) {
					pageNumList.push(<li key={i} className="c-pager__item"><Link to={`/${path}${year}/list-${i}`} className={classNames("c-pager__anchor", { 'is-active': i === list.paging.page_no })}>{i}</Link></li>);
				}
			}
			else {
				if (pageNum - 2 <  i && pageNum + 3 > i) {
					pageNumList.push(<li key={i} className="c-pager__item"><Link to={`/${path}${year}/list-${i}`} className={classNames("c-pager__anchor", { 'is-active': i === list.paging.page_no })}>{i}</Link></li>);
				}
			}
		}
		return pageNumList
	}

	const buildTo = (type) => {
		const pageNum = parseInt(page);

		if (type === 'prev') {
			if (pageNum === 1) {
				return (
					<span className="c-pager__prev is-diabled">{t('paging_prev')}<span className="c-pager__arrow" style={{ backgroundImage:`url(${require('assets/img/rocket-on/img-arrow-l2r--white.svg')})`}}></span></span>
				)
			} else {
				return (
					<Link to={`/${path}${year}/list-${pageNum - 1}`} className="c-pager__prev">{t('paging_prev')}<span className="c-pager__arrow" style={{ backgroundImage:`url(${require('assets/img/rocket-on/img-arrow-l2r--white.svg')})`}}></span></Link>
				);
			}
		}
		else {

			if (pageNum === list.paging.page_max) {
				return (
					<span className="c-pager__next is-diabled">{t('paging_next')}<span className="c-pager__arrow" style={{ backgroundImage:`url(${require('assets/img/rocket-on/img-arrow-l2r--white.svg')})`}}></span></span>
				)
			} else {
				return (
					<Link to={`/${path}${year}/list-${pageNum + 1}`} className="c-pager__next">{t('paging_next')}<span className="c-pager__arrow" style={{ backgroundImage:`url(${require('assets/img/rocket-on/img-arrow-l2r--white.svg')})`}}></span></Link>
				);
			}
		}
	}

	const buildPaging = () => {

		if (list === null) return false;

		if (list.data.data.length === 0) {
			return null
		}

		return (
			<div className="p-topics-list__pager c-pager">
				{buildTo('prev')}
				<ol className="c-pager__list">
					{buildPageNum()}
				</ol>
				{buildTo('next')}
			</div>
		)
	}
	return (
		<>
			{buildPaging()}
		</>
	)
}
