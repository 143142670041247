import React from "react";
import { useLocation, Link } from "react-router-dom";
import classNames from 'classnames';
import { useTranslation } from "react-i18next";
import { getLocale } from "@karpeleslab/klbfw";

export default function GuideNav() {
	const { t } = useTranslation();
	const currentLng = getLocale();
	const location = useLocation();

	return (
		<>
			<div className="c-navLocal">
				<ul className="c-navLocal__list">
					<li className="c-navLocal__item"><Link to="/guide" className={classNames("c-navLocal__anchor", {
						'c-navLocal__anchor--active': location.pathname === "/guide"
					})}>{t('common_user_guide')}</Link></li>
					<li className="c-navLocal__item"><Link to="/access" className={classNames("c-navLocal__anchor", {
						'c-navLocal__anchor--active': location.pathname === "/access"
					})}>{t('common_access')}</Link></li>
					<li className="c-navLocal__item"><a href={`https://booking.ihic.jp/l/${currentLng}/`} className={classNames("c-navLocal__anchor")}>{t('common_booking')}</a></li>
					<li className="c-navLocal__item"><Link to="/faq" className={classNames("c-navLocal__anchor", {
						'c-navLocal__anchor--active': location.pathname === "/faq"
					})}>{t('common_faq')}</Link></li>
					<li className="c-navLocal__item"><Link to="/manners" className={classNames("c-navLocal__anchor", {
						'c-navLocal__anchor--active': location.pathname === "/manners"
					})}>{t('common_manners')}</Link></li>
				</ul>
			</div>
		</>
	);
}
