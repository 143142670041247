import React, { useEffect, useState } from "react";
import { useRest, useRestRefresh } from '@karpeleslab/react-klbfw-hooks';
import { Link, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

// component
import Helmet from 'components/helmet/helmet';
import Paging from 'components/paging/paging';
import ComingSoon from "components/comingsoon/comingsoon";
import topicsDefault from "assets/img/rocket-on/img-topics-default.png";

export default function EventList() {
	const { t } = useTranslation();
	const location = useLocation();
	const { pageNo } = useParams();
	const imageVariation = 'format=jpeg&strip&scale_crop=374x247';
	const [page, setPage] = useState( pageNo === void 0 ? 1 : pageNo);

	const [articleList, setArticle] = useRest("Content/Cms/@news:search",{
		page_no: page,
		results_per_page:8,
		query: {
			tag: 'events'
		},
		"image_variation": imageVariation,
		sort: "published:desc"
	});

	const evemtsRefresh = useRestRefresh("Content/Cms/@news:search",{
		page_no: page,
		results_per_page:8,
		query: {
			tag: 'events'
		},
		"image_variation": imageVariation,
		sort: "published:desc"
	});

	useEffect(() => {
		setArticle(null)
		setPage(pageNo === void 0 ? 1 : pageNo)
	}, [location.pathname, setArticle, pageNo])

	useEffect(() => {
		if (articleList === null) {
			evemtsRefresh()
		}
	}, [page, articleList, evemtsRefresh])

	const buildArticle = () => {

		if (articleList === null) return false;
		
		if (articleList.data.data.length === 0) {
			return <ComingSoon />
		}
		else {
			return (
				<div className="c-topics">
					<ul className="c-topics__list">
						{
							articleList.data.data.map((item, idx) => {
								return (
									<li key={idx} className="c-topics__item">
										<a href={`/event/${item.Slug}`} className="c-topics__anchor">
											<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={!item.Drive_Item ? topicsDefault :item.Drive_Item.Media_Image[imageVariation]} alt="" className="c-topics__img"/>
											<span className="c-topics__date">{moment(item.Published.unix * 1000).format('YYYY/MM/DD')}</span>
											<span className="c-topics__title">{item.Title}</span>
										</a>
									</li>
								)
							})
						}
					</ul>
					<Paging list={articleList} page={page} path='topics' />
				</div>
			)
		}	
	}


	return (
		<>
			<Helmet>
				<title>{t('common_event_title')} : {t('common_ihic')}</title>
			</Helmet>
			<h1 className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_event_title')}</span>
					<span className="c-pageHeader__eng c-pageHeader__eng--2lines">{t("common_event_title_en")}</span>
				</div>
			</h1>

			<div className="c-topicPath c-block">
				<ol className="c-topicPath__list">
					<li className="c-topicPath__item"><Link to="/" className="c-topicPath__anchor">{t('common_home')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span>{t('common_event_list')}</li>
				</ol>
			</div>


			<div className="p-topics-list">
				{buildArticle()}
			</div>

		</>
	);
}
