import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// page
import Layout from 'components/layout/layout';
import Index from 'views/index/index';
import Guide from 'views/guide/guide';
import Access from 'views/access/access';
import Faq from 'views/faq/faq';
import Manners from 'views/manners/manners';
import About from 'views/about/about';
import Exhibition from 'views/exhibition/exhibition';
import Guidebook from 'views/guidebook/guidebook';
import Industrial from 'views/industrial/industrial';
import Privacy from 'views/privacy/privacy';
import Publications from 'views/publications/publications';
import Sitemap from 'views/sitemap/sitemap';
import Info from 'views/info/info';
import InfoDetail from 'views/info/detail';
import EventList from 'views/event/list';
import EventDetail from 'views/event/detail';
import NotFound from 'views/notfound/notfound';


const Mail = () => {
	return <Switch>
	</Switch>
}


const Pages = () => {
	return <Layout>
		<Switch>
			<Route exact path="/">
				<Index/>
			</Route>
			<Route exact path="/guide">
				<Guide />
			</Route>
			<Route exact path="/access">
				<Access />
			</Route>
			<Route exact path="/faq">
				<Faq />
			</Route>
			<Route exact path="/manners">
				<Manners />
			</Route>
			<Route exact path="/about-us">
				<About />
			</Route>
			<Route exact path="/exhibition">
				<Exhibition />
			</Route>
			<Route exact path="/guidebook">
				<Guidebook />
			</Route>
			<Route exact path="/industrial">
				<Industrial />
			</Route>
			<Route exact path="/privacy">
				<Privacy />
			</Route>
			<Route exact path="/publications">
				<Publications />
			</Route>
			<Route exact path="/sitemap">
				<Sitemap />
			</Route>
			<Route exact path="/info">
				<Info />
			</Route>
			<Route exact path="/info/:year">
				<Info />
			</Route>
			<Route exact path="/info/:year/list-:pageNo">
				<Info />
			</Route>
			<Route exact path="/info/:year/:slug">
				<InfoDetail />
			</Route>
			<Route exact path="/event">
				<EventList />
			</Route>
			<Route exact path="/event/list-:pageNo">
				<EventList />
			</Route>
			<Route exact path="/event/:slug">
				<EventDetail />
			</Route>
			<Redirect exact from="/topics" to="/event" />
			<Redirect exact from="/topics/list-:pageNo" to="/event/list-:pageNo" />
			<Redirect exact from="/topics/:slug" to="/event/:slug" />
			<Route>
				<NotFound />
			</Route>
		</Switch>
	</Layout>
}

const Core = () => {
	return (
		<Switch>
			<Route path="/mail">
				<Mail/>
			</Route>
			<Route>
				<Pages/>
			</Route>
		</Switch>
	);
}

const Routing = () => {
	return <Core/>
}

export default Routing;
