import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocale } from "@karpeleslab/klbfw";

// component
import AboutNav from "components/aboutNab/aboutNav";
import Helmet from 'components/helmet/helmet';

export default function About() {
	const { t } = useTranslation();
	const currentLng = getLocale();

	const buildProfile = () => {

		switch (currentLng) {
		case "en-US":
			return (
				<>
					<p className="p-about-profile__title">{t('about_director')}</p>
					<p className="p-about-profile__name">{t('about_kato_name')}</p>
					<p className="p-about-profile__title p-about-profile__title2">{t('about_director_sub')}</p>
				</>
			)
		case "ko-KR":
			return (
				<>
					<p className="p-about-profile__title">{t('about_director')}</p>
					<p className="p-about-profile__name">{t('about_director_sub')}　{t('about_kato_name')}</p>
				</>
			)
		default:
			return (
				<>
					<p className="p-about-profile__title">{t('about_director')}</p>
					<p className="p-about-profile__name">{t('about_director_sub')}　{t('about_kato_name')}</p>
				</>
			)
		}
	}


	return (
		<>
			<Helmet>
				<title>{t('common_about_us')} : {t('common_ihic')}</title>
			</Helmet>
			<h1 className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_ihic')}</span>
					<span className="c-pageHeader__eng c-pageHeader__eng--2lines">INDUSTRIAL HERITAGE <br/>INFORMATION CENTRE</span>
				</div>
			</h1>

			<div className="c-topicPath c-block">
				<ol className="c-topicPath__list">
					<li className="c-topicPath__item"><Link to="/" className="c-topicPath__anchor">{t('common_home')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span><Link to="" className="c-topicPath__anchor">{t('common_ihic')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span>{t('common_about_us')}</li>
				</ol>
			</div>
			<AboutNav />
			<div className="p-about-header c-block">

				<div className="p-about-header__text">
					<h2 className="c-heading--lv2">{t('common_ihic')}</h2>
					<p className="">{t('about_text_about')}</p>
					<p className="">{t('about_text_about_2')}</p>
				</div>

				<p className="p-about-header-img">
					<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-about-image@2x.jpg')} alt="" className="p-about-header-img__img"/>
					<span className="p-about-header-img__text" dangerouslySetInnerHTML={{__html: t('text-top-ihic-img-alt')}} />
				</p>

			</div>
			<div className="p-about-body c-block">
				<ul className="p-about-body__list">
					<li className="p-about-body__item">
						<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-about-liquidGalaxy.jpg')} alt="p-about-body__img"/>
						<span className="p-about-body__text">{t('about_img_lg')}</span>
					</li>
					<li className="p-about-body__item">
						<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-about-panel.jpg')} alt="p-about-body__img"/>
						<span className="p-about-body__text">{t('about_img_pannel')}</span>
					</li>
					<li className="p-about-body__item">
						<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-about-tablet.jpg')} alt="p-about-body__img"/>
						<span className="p-about-body__text">{t('about_img_tablet')}</span>
					</li>
				</ul>
				<div className="p-about-body__btnContainer">
					<Link to="/exhibition" className="c-btn c-btn--regular">{t('top-text-exhibits')}</Link>
				</div>
			</div>

			<div className="p-about-greeting">
				<div className="c-block">
					<h2 className="c-heading--lv2">{t('about_greetings')}</h2>

					<p className="p-about-greeting__paragraph">{t('about_kato')}</p>
					<p className="p-about-greeting__paragraph">{t('about_kato_1')}</p>
					<p className="p-about-greeting__paragraph">{t('about_kato_2')}</p>
					<div className="p-about-profile">
						<img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-about-profile-president.jpg')} alt="" className="p-about-profile__photo"/>
						<div className="p-about-profile__wrapper">
							{buildProfile()}
						</div>
					</div>
				</div>
			</div>

			<div className="p-about-outline">

				<div className="p-about-business c-block c-block--w740">
					<h2 className="c-heading--lv2">{t('about_businesses')}</h2>
					<div className="p-about-business__listContainer">
						<ol className="c-list--ol-withoutDot">
							<li className="c-list__item">{t('about_think_tank')}</li>
							<li className="c-list__item">{t('about_research')}</li>
							<li className="c-list__item">{t('about_management')}</li>
							<li className="c-list__item">{t('about_training')}</li>
						</ol>
						<ol className="c-list--ol-withoutDot" style={{
							counterReset: "ol 4"
						}}>
							<li className="c-list__item">{t('about_strategy')}</li>
							<li className="c-list__item">{t('about_digital')}</li>
							<li className="c-list__item">{t('about_local')}</li>
						</ol>
					</div>
				</div>

				<hr className="c-separator"/>

				<div className="p-about-report c-block c-block--w740">
					<h2 className="c-heading--lv2">{t('about_activities')}</h2>
					<p>{t('about_updated')}</p>
				</div>

				<hr className="c-separator"/>

				<div className="p-about-info c-block c-block--w740">
					<h2 className="c-heading--lv2">{t('about_document')}</h2>
					<p className="p-about-info__text">{t('about_reference')}</p>
				</div>

			</div>

		
		</>
	);
}
