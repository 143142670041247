import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocale } from "@karpeleslab/klbfw";

// component
import GuideNav from "components/guideNav/guidNav";
import Helmet from 'components/helmet/helmet';

export default function Access() {
	const { t } = useTranslation();
	const currentLng = getLocale();

	
	const buildMap = () => {

		switch (currentLng) {
		case "en-US":
			return require('assets/img/rocket-on/img-access-map-en-US.jpg');
		case "ko-KR":
			return require('assets/img/rocket-on/img-access-map-ko-KR.jpg');
		default:
			return require('assets/img/rocket-on/img-access-map.jpg');
		}

	}


	return (
		<>
			<Helmet>
				<title>{t('common_access')} : {t('common_ihic')}</title>
			</Helmet>
			<h1 className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_access')}</span>
					<span className="c-pageHeader__eng">ACCESS</span>
				</div>
			</h1>

			<div className="c-topicPath c-block">
				<ol className="c-topicPath__list">
					<li className="c-topicPath__item"><Link to="/" className="c-topicPath__anchor">{t('common_home')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span><Link to="" className="c-topicPath__anchor">{t('common_visiting')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span>{t('common_access')}</li>
				</ol>
			</div>
			<GuideNav />

			<section className="p-access c-block">

				<h2 className="c-heading--lv2">{t('access_address_title')}</h2>

				<div className="p-access-address">
					<p className="p-access-address__text" dangerouslySetInnerHTML={{__html: t('access_address')}} />
					<p className="p-access-address__btn"><a rel="noopener noreferrer" href="https://goo.gl/maps/3wLJ5YankBDrBBMc6" target="_blank" className="c-btn c-btn--regular">{t('access_to_map')}</a></p>
				</div>

				<div id="map" className="p-access-map"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={buildMap()} alt="産業遺産情報センター マップ" className="c-topics__img"/></div>
				<p className="p-access-address__note">{t('access_map_about')}</p>

				<div className="p-access-wayFromSta">
					<h3 className="c-heading--lv3" dangerouslySetInnerHTML={{__html: t('access_from')}}></h3>
					<div className="p-access-wayFromSta__container">
						<p className="p-access-wayFromSta__img"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-access-entrance.jpg')} alt="産業遺産情報センター 入り口"/></p>
						<ol className="c-list--ol p-access-wayFromSta__text">
							<li className="c-list__item">{t('access_step1')}</li>
							<li className="c-list__item">{t('access_step2')}</li>
							<li className="c-list__item">{t('access_step3')}</li>
							<li className="c-list__item">{t('access_step4')}</li>
							<li className="c-list__item">{t('access_step5')}</li>
							<li className="c-list__item">{t('access_step6')}</li>
						</ol>
					</div>
				</div>

			</section>
		
		</>
	);
}
