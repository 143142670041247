import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocale } from "@karpeleslab/klbfw";


// component
import GuideNav from "components/guideNav/guidNav";
import Helmet from 'components/helmet/helmet';

export default function Guide() {
	const { t } = useTranslation();
	const currentLng = getLocale();
	
	return (
		<>
			<Helmet>
				<title>{t('common_user_guide')} : {t('common_ihic')}</title>
			</Helmet>
			<h1 className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_user_guide')}</span>
					<span className="c-pageHeader__eng">{t('common_en_user_guide')}</span>
				</div>
			</h1>

			<div className="c-topicPath c-block">
				<ol className="c-topicPath__list">
					<li className="c-topicPath__item"><Link to="/" className="c-topicPath__anchor">{t('common_home')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span><Link to="" className="c-topicPath__anchor">{t('common_visiting')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span>{t('common_user_guide')}</li>
				</ol>
			</div>
			<div className="c-block">
				<section className="c-info">
					<p className="c-info__lead">{t('subtitle_info_booking')}</p>
					<p className="c-info__text">{t('text_info_booking')}</p>
					{currentLng === 'en-US' && <p className="c-info__text">{t('text_info_booking_3')}</p>}
					<p className="c-info__link">
						<a href={`https://booking.ihic.jp/l/${currentLng}/`} className="c-btn c-btn--reserve">{t('btn_booking')}</a>
					</p>
				</section>
			</div>

			<GuideNav />

			{/* <section className="p-guide-covid19 c-block">
				<h2 className="p-guide-covid19__heading">{t('text_extra_safety')}</h2>
				<div className="u-container u-container--2column">
					<div className="u-container__item">
						<p className="p-guide-covid19__heading--lv2">{t('text_admission')}</p>
						<ul className="c-list--ul">
							<li className="c-list__item">{t('text_body_temperature')}</li>
							<li className="c-list__item">{t('text_face_cover')}</li>
							<li className="c-list__item">{t('text_hand_sanitiser')}</li>
							<li className="c-list__item">{t('text_personal_card')}</li>
						</ul>
						<p className="c-text__note">{t('text_extra_text')}</p>
					</div>
					<div className="u-container__item">
						<p className="p-guide-covid19__heading--lv2">{t('text_extra_safety_2')}</p>
						<ul className="c-list--ul">
							<li className="c-list__item">{t('text_extra_keep')}</li>
							<li className="c-list__item">{t('text_extra_touch')}</li>
							<li className="c-list__item">{t('text_extra_book')}</li>
						</ul>
						<p className="c-text__note">{t('text_extra_alchol')}</p>
					</div>
				</div>
			</section>

			<hr className="c-separator"/> */}

			<div className="c-block p-guide-container">
				<div className="u-container u-container--2column">
					<section className="u-container__item p-guide-openingHours">
						<h2 className="c-heading--lv2">{t('text_business_hours')}</h2>
						<table className="c-table">
							<tbody>
								<tr>
									<th className="c-table__header">{t('text_business_hour')}</th>
									<td className="c-table__data">{t('text_business_hour_time')}</td>
								</tr>
								<tr>
									<th className="c-table__header">{t('text_closed')}</th>
									<td className="c-table__data" dangerouslySetInnerHTML={{__html:t('text_closed_text')}}></td>
								</tr>
							</tbody>
						</table>
						<p className="p-guide-openingHours__text">{t('text_access_info')}</p>
						{currentLng === 'ja-JP' && <p className="p-guide-openingHours__text">{t('text_access_info_5')}</p>}
						<p className="p-guide-openingHours__text">{t('text_access_info_2')}</p>
						<p className="p-guide-openingHours__text">{t('text_access_info_3')}</p>
						{currentLng !== 'en-US' && <p className="p-guide-openingHours__text">{t('text_access_info_4')}</p>}
					</section>
					<div className="u-container__item">
						<section className="p-guide-price">
							<h2 className="c-heading--lv2">{t('text_admission_pay')}</h2>
							<p className="c-price">{t('text_admission_free')}</p>
						</section>
						<section className="p-guide-address">
							<h2 className="c-heading--lv2">{t('text_location_title')}</h2>
							<p className="p-guide-address__text">{t('text_location_text')}</p>
							<p className="p-guide-address__btnContainer"><Link to="/access" className="c-btn c-btn--regular">{t('common_access')}</Link></p>
						</section>
					</div>
				</div>
			</div>
			<hr className="c-separator"/>

			<section className="p-guide-barrierFree c-block">
				<h2 className="c-heading--lv2">{t('text_accessibility_title')}</h2>
				<ul className="c-list--ul">
					<li className="c-list__item">{t('text_accessibility_text')}</li>
					<li className="c-list__item">{t('text_hearing')}</li>
				</ul>
			</section>

			<hr className="c-separator"/>

			<section className="p-guide-contact c-block">
				<h2 className="c-heading--lv2">{t('text_enquiry')}</h2>
				<div className="p-guide-contact__container">
					<table className="c-table p-guide-contact__table">
						<tbody>
							<tr>
								<th className="c-table__header">{t('text_tell')}</th>
								<td className="c-table__data">{t('text_tell_number')}</td>
							</tr>
						</tbody>
					</table>
					<p className="p-guide-contact__reserve">{t('text_booking_more')}</p>
					<p className="p-guide-contact__btnContainer"><a href={`https://booking.ihic.jp/l/${currentLng}/`} className="c-btn c-btn--regular">{t('common_booking')}</a></p>
				</div>
			</section>

			<hr className="c-separator"/>
		
		</>
	);
}
