import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocale } from "@karpeleslab/klbfw";

// component
import GuideNav from "components/guideNav/guidNav";
import Helmet from 'components/helmet/helmet';

export default function Manners() {
	const { t } = useTranslation();
	const currentLng = getLocale();

	const manners = [
		[
			'light',
			t('title_attension'),
			t('text_attension')
		],
		[
			'quietness',
			t('title_unacceptable'),
			t('text_unacceptable')
		],
		[
			'photo',
			t('title_photo'),
		],
		[
			'touch',
			t('title_case'),
		],
		[
			'eating',
			t('title_eat'),
			t('text_eat')
		],
		[
			'smoking',
			t('title_smoking'),
			t('text_smoking')
		],
		[
			'tel',
			t('title_mobile'),
		],
		[
			'pet',
			t('title_pet'),
		],
		[
			'drink',
			t('text_alcohol'),
		],
		[
			'fire',
			t('title_bring'),
		],
		[
			'baggage',
			t('title_locker'),
		],
		[
			'papers',
			t('title_document'),
		],
		[
			'activity',
			t('title_politial'),
		]
	]


	const buildText = (item) => {

		if (item[2] === void 0) {
			return (
				<p className="c-manners-item__heading is-heading-only">{item[1]}</p>
			)
		} else if (item[0] === 'light' && currentLng === 'en-US') {
			return (
				<p className="c-manners-item__heading is-heading-only">{item[1]}</p>
			)
		} else if (item[0] === 'smoking' && currentLng === 'en-US') {
			return (
				<p className="c-manners-item__heading is-heading-only">{item[1]}</p>
			)
		} else {
			return (
				<>
					<p className="c-manners-item__heading" dangerouslySetInnerHTML={{__html: item[1]}}></p>
					<p className="c-manners-item__text" dangerouslySetInnerHTML={{__html: item[2]}}></p>
				</>
			)
		}
	}

	const buildList = () => {
		return manners.map((item, idx) => {
			return (
				<div key={idx} className={`c-manners-item is-${item[0]}`}>
					<span className="c-manners-item__icon" style={{
						backgroundImage: `url(${require(`assets/img/rocket-on/img-manner-${item[0]}.svg`)})`
					}}></span>
					{buildText(item)}
				</div>
			)
		})
	}

	return (
		<>
			<Helmet>
				<title>{t('common_manners')} : {t('common_ihic')}</title>
			</Helmet>
			<h1 className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_manners')}</span>
					<span className="c-pageHeader__eng">ETIQUETTE</span>
				</div>
			</h1>

			<div className="c-topicPath c-block">
				<ol className="c-topicPath__list">
					<li className="c-topicPath__item"><Link to="/" className="c-topicPath__anchor">{t('common_home')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span><Link to="" className="c-topicPath__anchor">{t('common_visiting')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span>{t('common_manners')}</li>
				</ol>
			</div>
			<GuideNav />

			<section className="p-manners-list">
				<h2 className="c-heading--lv2">{t('title_museum_rules')}</h2>
				<p className="p-manners-leadCopy" dangerouslySetInnerHTML={{__html: t('text_museum_rules')}} />
				<h3 className="p-manners-list__heading">{t('title_etiquittes')}</h3>
				<div className="p-manners-list__container">
					{buildList()}
				</div>
			</section>
		
		</>
	);
}
