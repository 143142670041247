import React from "react";
import { useRest } from '@karpeleslab/react-klbfw-hooks';
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

// component
import Helmet from 'components/helmet/helmet';
import NotFound from 'views/notfound/notfound';

export default function InfoDetail() {

	const { t } = useTranslation();
	const { slug } = useParams();
	const [article] = useRest(`Content/Cms/@news:loadSlug`, { slug: slug }, true);

	const buildArtcle = () => {

		if (article === null) return false;
		
		if (article.data.content_cms_entry_data === null || article.data.content_cms_entry_data === void 0) {
			return <p style={{textAlign: "center"}}>{t('article_not')}</p>
		}
		else {
			return (
				<>
					<Helmet>
						<title>{article.data.content_cms_entry_data.Title} : {t('common_ihic')}</title>
					</Helmet>
					<div className="c-topicPath c-block">
						<ol className="c-topicPath__list">
							<li className="c-topicPath__item"><Link to="/" className="c-topicPath__anchor">{t('common_home')}</Link></li>
							<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span><Link to="/info" className="c-topicPath__anchor">{t('common_info')}</Link></li>
							<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span>{article.data.content_cms_entry_data.Title}</li>
						</ol>
					</div>
					<div className="p-info-entry c-block c-block--w740">
						<h1 className="p-info-entry-heading">
							<span className="p-info-entry-heading__date">{moment(article.data.content_cms_entry_data.Published.unix * 1000).format('YYYY/MM/DD')}</span>
							<span className="p-info-entry-heading__title">{article.data.content_cms_entry_data.Title}</span>
						</h1>
						<div dangerouslySetInnerHTML={{__html: article.data.content_cms_entry_data.Contents}} className="p-info-entry__body">
						</div>
					</div>
					<div className="p-info-entry__btns">
						<Link to="/info" className="c-btn c-btn--regular">{t('common_back_to_news')}</Link>
					</div>
				</>
			)
		}	
	}


	if ( article === false ) return <NotFound />

	return (
		<>
			<div className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_info')}</span>
					<span className="c-pageHeader__eng">INFORMATION</span>
				</div>
			</div>
			{buildArtcle()}
		</>
	);
}
