import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getLocale } from "@karpeleslab/klbfw";

// component
import Helmet from 'components/helmet/helmet';

export default function Publications() {
	const { t } = useTranslation();
	const currentLng = getLocale();

	return (
		<>
			<Helmet>
				<title>{t('common_publications')} : {t('common_ihic')}</title>
			</Helmet>
			<h1 className="c-pageHeader">
				<div className="c-pageHeader__container">
					<span className="c-pageHeader__jp">{t('common_publications')}</span>
					<span className="c-pageHeader__eng">PUBLICATIONS</span>
				</div>
			</h1>
	

			<div className="c-topicPath c-block">
				<ol className="c-topicPath__list">
					<li className="c-topicPath__item"><Link to="/" className="c-topicPath__anchor">{t('common_home')}</Link></li>
					<li className="c-topicPath__item"><span className="c-topicPath__separator" style={{ backgroundImage: `url(${require('assets/img/rocket-on/bullet-topicPath.svg')})`}}></span>{t('common_publications')}</li>
				</ol>
			</div>

			<div className="p-publications">

				<p className="p-publications__img"><img onContextMenu={(e) => {e.preventDefault(); e.stopPropagation(); return false}} src={require('assets/img/rocket-on/img-publications.png')} alt="" className=""/></p>

				<div className="p-publications__container c-block c-block--w740">
					<p className="p-publications__text" dangerouslySetInnerHTML={{__html: t('publications_text')}}></p>
					{currentLng !== "en-US" && <p className="p-publications__text">{t('publications_text_2')}</p>}
					{currentLng !== "en-US" && <p className="p-publications__text">{t('publications_text_3')}</p>}
					{currentLng !== "en-US" && <p className="p-publications__text"><a href="http://www.japansmeijiindustrialrevolution.com/conservation/interpretation.html" className="p-publications__anchor">http://www.japansmeijiindustrialrevolution.com/conservation/interpretation.html</a></p>}
					{currentLng !== "en-US" && <p className="p-publications__text">{t('publications_text_5')}</p>}
				</div>

			</div>
		
		</>
	);
}
